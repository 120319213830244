.aLink {
  position: absolute;
  color: #ffffff !important;
  padding-top: -100px;
  padding-right: 40px;
}

.aLink:hover {
  color: rgb(172, 172, 172);
}

.infoChart {
  font-size: 30px;
  position: absolute;
  margin-bottom: 5px;
  margin-right: 5px;
  bottom: 0;
  right: 0;
}

.infoChartCancel {
  font-size: 80px;
  position: absolute;
  margin-bottom: 5px;
  margin-right: 5px;
  bottom: 0;
  right: 0;
}

.profCont {
  display: flex;
  width: 100%;
  align-content: center;
}

#section-feature ul {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  margin: 100px 0 0 0;
  padding: 0;
  list-style: none;
  position: relative;
}

#section-feature li {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  height: 240px;
  padding: 2%;
  float: left;
  margin-bottom: 40px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.animation {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.pageTitle sup {
  font-size: 0.6em;
  color: #333;
}
.well {
  padding: 3%;
  margin: 20px auto;
  border: none;
  text-align: center;
}
.well p {
  font-weight: 300;
}
.content p {
  font-weight: 300;
}
.carddContainer {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;

  /*depth of the elements */
  -webkit-perspective: 800px;
  -moz-perspective: 800px;
  -o-perspective: 800px;
  perspective: 800px;

  /*border: 1px solid #ff0000;*/
  padding-left: 1%;
}
.secondRow {
  margin-top: -1.4%;
}

.cardd {
  width: 250px;
  height: 200px;
  cursor: pointer;

  /*transition effects */
  -webkit-transition: -webkit-transform 0.6s;
  -moz-transition: -moz-transform 0.6s;
  -o-transition: -o-transform 0.6s;
  transition: transform 0.6s;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.cardd.flipped {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.cardd .front {
  display: block;
  height: 100%;
  width: 100%;
  line-height: 60px;
  color: white;
  text-align: center;
  font-size: 4em;
  position: absolute;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  box-shadow: 3px 5px 20px 2px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16),
    0 2px 5px rgba(0, 0, 0, 0.26);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16), 0 2px 5px rgba(0, 0, 0, 0.26);
}

.cardd .back {
  display: block;
  height: 100%;
  width: 100%;
  line-height: 60px;
  color: white;
  text-align: center;
  font-size: 4em;
  position: absolute;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  box-shadow: 3px 5px 20px 2px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16),
    0 2px 5px rgba(0, 0, 0, 0.26);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16), 0 2px 5px rgba(0, 0, 0, 0.26);
}

.cardd .back {
  width: 100%;
  padding-left: 3%;
  padding-right: 3%;
  font-size: 16px;
  text-align: left;
  line-height: 25px;
}

/*Colors for front and back applied here*/
.carddContainer .cardd .front {
  background: #e06e10;
}

.carddContainer .cardd .back {
  background: #3f3f3f;
}

.desccardd {
  margin-left: 5px;
  margin-right: 5px;
}

h3.carddTitle {
  margin-left: 5px;
  margin-right: 5px;
  font-size: 20px;
  margin-bottom: 20px;
  line-height: 1.2em;
  margin-top: 8%;
  font-weight: 600;
}

.content h3.carddTitle {
  margin-top: 0%;
}

.content {
  padding: 4%;
  font-weight: 100;
  text-align: left;
  font-weight: bold;
}

@media (max-width: 640px) {
  #section-feature ul {
    grid-template-columns: 1fr;
  }
  #section-feature li {
    margin-left: 40px;
  }
}

@media (min-width: 641px) and (max-width: 980px) {
  #section-feature ul {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 981px) and (max-width: 1200px) {
  #section-feature ul {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
