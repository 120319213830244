.login {
  background-color: #ffebd1;
  display: flex;
  flex-direction: column;
}

p {
  padding-top: 20px;
  font-size: 0.9rem;
}

.btn-login {
  font-size: 1.2rem;
  font-weight: 700;
  width: 100%;
  border: 0px !important;
  letter-spacing: 0.05rem;
  padding: 0.75rem 1rem;
  color: white !important;
  background-color: #e4610e;
}

.btn-login:hover {
  background-color: #e4600ed0;
}

span {
  font-weight: 500;
}
